<template>
  <el-container>
    <div class="loading-logo" v-if="loading">
      <div>
        <h3>加载中，请耐心等待……</h3>
      </div>
    </div>
    <div class="w-100 warpper" v-else>
      <div class="banner" :style="{ 'background-image': `url(${require('../../../../assets/images/common/top_banner_' + theme_color + '.png')})` }">
        <h1>{{ title }}报告查看</h1>
        <p>
          <span v-if="subtitle">{{ subtitle }}</span>
        </p>
      </div>
      <div class="btn_tab" id="network-tab">
        <div class="fxbg">
          <div class="warpper">
            <div class="flex-container">
              <a data-href="#sjgs" class="selected cur-p" @click="switchTab(0, true)">事件概述</a>
              <a data-href="#qyfx" class="cur-p" @click="switchTab(1, true)">事件起源分析</a>
              <a data-href="#zsfx" class="cur-p" @click="switchTab(2, true)">事件走势分析</a>
              <a data-href="#tjfx" class="cur-p" @click="switchTab(3, true)">信息传播途经分析</a>
              <a data-href="#nrfx" class="cur-p" @click="switchTab(4, true)">传播内容分析</a>
            </div>
          </div>
        </div>
      </div>
      <div class="fxbg pt_0">
        <!-- 事件概述 -->
        <a name="sjgs" id="sjgs"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件概述
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">分析时间段内监测到的网媒信息<br>传播过程，得出事件的整体情况</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p :class="row.text ? 'paragraph' : 'paragraph lh-30'" v-for="(row, index) in data.web_summary" :key="index">
                <strong v-if="row.text">{{ row.text }}：</strong>
                <span v-if="!isArray(row.summary)">{{ row.summary }}</span>
                <span v-for="(tag,index) in row.summary" :key="index" class="text-tag" v-else>{{ tag }}</span>
              </p>
            </div>
          </el-card>
        </div>
        <!-- 事件起源分析 -->
        <a name="qyfx" id="qyfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件起源分析
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">分析时间段内监测到的网媒信息<br>传播过程，描述事件传播源头</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p v-if="data.web_source.length < 1">暂无分析数据</p>
              <p :class="index == 0 ? 'paragraph lh-30' : 'paragraph'" v-for="(row, index) in data.web_source" :key="index">
                {{ row }}
              </p>
            </div>
          </el-card>
        </div>
        <!-- 事件走势分析 -->
        <a name="zsfx" id="zsfx"></a>
        <div class="warpper pt-20" v-if="data['web_event_trend'].isIncubation">
          <el-card class="box-card">
            <h3 class="mod-title">事件走势分析
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对网媒信息走势、媒体信息来源<br>类型分布和媒体事件走势综合<br>分析后，得出的结论</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <div v-for="(row, index) in data['web_trend']" :key="index">
                <div v-if="row.hasOwnProperty('text')">
                  <p class="paragraph lh-30">{{ row.text }}</p>
                  <p class="paragraph" v-for="(item, index) in row.item" :key="index">{{ item }}</p>
                </div>
                <div v-else>
                  <p class="paragraph">
                    <strong>{{ row.key }}：</strong>
                    <span v-for="(tag, index) in row.tags" :key="index" class="text-tag">{{ tag }}</span>
                  </p>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">网媒信息量走势
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，全部网媒、一般媒体和<br>政府网站发布的信息数量随时间变<br>化的趋势</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['web_number_trend'].data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="web_number_trend" v-show="data['web_number_trend'].data.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">媒体信息来源类型分布
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，一般媒体和政府网站<br>发布的信息占总信息数量的比重</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="web_type"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">媒体事件走势
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">根据事件走势，展示关键时间点<br>的传播媒体和传播内容</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20" v-if="!data['web_event_trend'].isIncubation">
              <div class="echarts_empty" v-if="data['web_event_trend'].data.length < 1">
                <div class="img"></div>
              </div>
              <!-- 时间轴 -->
              <div class="demo" v-else>
                <div class="main-timeline">
                  <div class="timeline" v-for="(row, index) in data['web_event_trend'].data" :key="index">
                    <div class="timeline-content">
                      <div class="circle">
                        <p class="title mar-t-10"><a :href="row.source_url" target="_blank" :title="row.title">{{ row.title }}</a></p>
                        <p class="name"><a>{{ row.media }}</a></p>
                      </div>
                      <div class="cont">
                        <span class="year">{{ row.date }}</span>
                        <div class="icon"><span></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-lr-20" v-else>
              <div class="w-100 h-400" id="media-event-trend"></div>
              <div class="demo2">
                <div class="main-timeline2" v-for="(row, index) in data['web_event_trend'].data.timeline" :key="index">
                  <div class="item" v-if="row.type === 'point'">
                    <div class="main-title">
                      <div class="point">
                        <p v-for="(point, index) in row.points" :key="index">
                          <img :src="findPointImg(point)" :alt="point" width="18" type="image/svg+xml" />
                          <span>{{ point }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="circle">
                          <div class="circle_title">
                            <h4>
                              <a :href="row.article.source_url" target="_blank">{{ row.article.title }}</a>
                            </h4>
                            <dl>
                              <dd v-for="(media, index) in row.article.medias" :key="index">
                                <a>{{ media }}</a><span class="bd">|</span>
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <div class="cont">
                          <span class="year">{{ row.date }}</span>
                          <div class="icon"><span></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item item_title" v-else>
                    <div class="main-title">
                      <h3>{{ row.state }}</h3>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content bd_n">
                        <div class="circle">
                          <div class="mod-list">
                            <ul>
                              <li v-for="(article, index) in row.articles" :key="index">
                                <span class="num bg-555" v-if="index == 0">1</span>
                                <span class="num bg-689" v-else-if="index == 1">2</span>
                                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                                <a class="mod-list-title2" :href="article.source_url" target="_blank">{{ article.title }}</a>
                                <a class="site"><span>|</span>{{ article.media }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 信息传播途经分析 -->
        <a name="tjfx" id="tjfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">信息传播途经分析
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对主要发布渠道、事件关键媒体<br>走势、事件传播路径综合分析后，<br>得出的结论</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30" v-for="(row, index) in data['web_event_spread_path']" :key="index">
                <strong v-if="row.keyword">{{ row.keyword }}：</strong>
                <span v-if="!isArray(row.text)">{{ row.text }}</span>
                <span v-for="(tag, index) in row.text" :key="index" class="text-tag" v-else>{{ tag }}</span>
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件传播路径
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">事件在网媒的传播路径，通过关键<br>节点可以找出关键的传播媒体</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-if="JSON.stringify(data['web_spread_path']) === '{}'">
                <div class="img"></div>
              </div>
              <div class="h-450" id="web_spread_path" v-show="JSON.stringify(data['web_spread_path']) !== '{}'"></div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card zyfbqd">
            <h3 class="mod-title">主要发布渠道
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，发布相关信息数量最高的<br>10个媒体及其最重要的3个观点</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="mod-list">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">媒体名称</span><span class="bt">标题一</span><span class="bt">标题二</span><span class="bt">标题三</span><span class="xxzs">信息总数</span><span class="tag">趋势简图</span></h3>
                  </li>
                  <li v-for="(row, index) in data['web_main_platform']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :title="row.media">{{ row.media }}</a>
                    <a class="bt" v-for="(article, index) in row.article" :href="article.source_url" target="_blank" :key="index" :title="article.title">{{ article.title }}</a>
                    <span class="xxzs">{{ row.total }}条</span>
                    <div class="tag mini-trend" :miniid="index"></div>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件关键媒体走势
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，发布相关信息数量最高的<br>10个媒体随时间变化的趋势</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['web_key_media_trend'].data.length < 1">
                <div class="img"></div>
              </div>
              <div class="h-450" id="web_key_media_trend" v-show="data['web_key_media_trend'].data.length >= 1"></div>
            </div>
          </el-card>
        </div>
        <!-- 传播内容分析 -->
        <a name="nrfx" id="nrfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">传播内容分析
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对事件传播内容、事件传播情绪和<br>事件传播内容走势进行综合分析后，<br>得出的结论</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">{{ data['web_spread_content_analyse'] }}</p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card sjcbnr echarts-box">
                <h3 class="mod-title">事件传播内容
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">对时间段内的事件内容进行分词及重<br>要关键词词频统计，展示词频最高的<br>50个重要关键词</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="w-100 flex">
                    <div class="mod-list" v-show="data['web_spread_content'].length >= 1">
                      <ul>
                        <li v-for="(row, index) in data['web_spread_content']" :key="index" v-if="index <= 9">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title2" :title="row.keyword">{{ row.keyword }}</a>
                        </li>
                      </ul>
                    </div>
                    <div class="w-100 h-290" id="web_spread_content" v-show="data['web_spread_content'].length >= 1"></div>
                    <div class="echarts_empty" v-show="data['web_spread_content'].length < 1">
                      <div class="img"></div>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card h-355 ov-v">
                <h3 class="mod-title">事件传播情绪
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">对时间段内的事件内容进行情感分析，提<br>取敏感信息中的负面关键词，非敏感信息<br>中的正面关键词，并进行词频统计，分别<br>展示词频最高的10个情感关键词</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20 pos-r ov-v">
                  <div class="echarts_empty" v-show="data['web_spread_emotion'].length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-290" id="web_spread_emotion" v-show="data['web_spread_emotion'].length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件传播内容走势
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，事件传播关键词随时间变化<br>的趋势</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['web_spread_content_trend'].data.length < 1">
                <div class="img"></div>
              </div>
              <div class="h-450" id="web_spread_content_trend" v-show="data['web_spread_content_trend'].data.length >= 1"></div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
// require("@/echarts2/echarts-all") //引入echarts2.2.7版本　绘制树状图
import {
  findPointImg,
  globalCreateChart,
  globalGetEcharts,
  globalMediaColor
} from '@/utils/helpers.js'
import { detail } from '@/api/analysis'
const d3 = require("d3");
export default {
  name: 'analysis-propagation-network',
  data() {
    return {
      data: {},
      loading: true,
      title: '',
      created: ''
    }
  },
  mounted() {
    this.loadData()
    this.$nextTick(() => {
      let _this = this
      window.onscroll = function() {
        let height = document.documentElement.scrollTop + 75
        if (height > 200) {
          $('#network-tab').addClass('scroll-tab')
          $('#network-tab').addClass('scroll')
        } else {
          $('#network-tab').removeClass('scroll')
          $('#network-tab').removeClass('scroll-tab')
        }
        let b = window.document.getElementById('qyfx').offsetTop
        let c = window.document.getElementById('zsfx').offsetTop
        let d = window.document.getElementById('tjfx').offsetTop
        let e = window.document.getElementById('nrfx').offsetTop - 300
        if (height < b) {
          _this.switchTab(0)
        } else if (height >= b && height < c) {
          _this.switchTab(1)
        } else if (height >= c && height < d) {
          _this.switchTab(2)
        } else if (height >= d && height < e) {
          _this.switchTab(3)
        } else {
          _this.switchTab(4)
        }
      }
    })
  },
  methods: {
    isArray(data) {
      return _.isArray(data)
    },
    switchTab(index, scrollTo = false) {
      if (scrollTo) {
        var h = 0
        switch (index) {
          case 0:
            document.documentElement.scrollTop = h
            break
          case 1:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('qyfx').offsetTop - h
            break
          case 2:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('zsfx').offsetTop - h
            break
          case 3:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('tjfx').offsetTop - h
            break
          case 4:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('nrfx').offsetTop - h
            break
        }
      }
      $('.flex-container a').removeClass('selected')
      $('.flex-container a:eq(' + index + ')').addClass('selected')
    },
    loadData() {
      detail({ id: this.$route.query.id })
        .then(res => {
          if (res.data.state) {
            this.data = JSON.parse(res.data.data.result)
            const { title, updated, subtitle, theme_color } = res.data.data
            this.title = title
            this.subtitle = subtitle
            this.theme_color = theme_color
            this.created = updated
            this.loading = false
            this.$nextTick(() => {
              this.createChart()
            })
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(res => {
          this.$message.error('加载数据失败，服务失败')
        })
    },
    createChart() {
      this.trendChart('web_number_trend')
      this.loopChart('web_type')
      this.pointChart('web_key_media_trend')
      this.pointChart('web_spread_content_trend')
      this.treeChart('web_spread_path')
      this.wordCloudChart('web_spread_content')
      this.wordPopChart('web_spread_emotion')
      if (this.data['web_event_trend'].isIncubation) {
        this.eventTrend('media-event-trend') // 该分析为事件时调用
      }
      let elements = window.document.getElementsByClassName('mini-trend')
      for (let i = 0; i < elements.length; i++) {
        this.miniTrendChart(i, elements[i])
      }
    },
    miniTrendChart(index, element) {
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          show: false
        },
        legend: {
          top: '5px',
          icon: 'circle',
          itemGap: 20,
          itemWidth: 10,
          itemHeight: 10,
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '40px',
          bottom: '20px',
          width: '90%',
          containLabel: true,
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          show: false
        },
        yAxis: { type: 'value', show: false },
        color: [
          '#83d944',
          '#f0a142 ',
          '#3178d2 ',
          '#568efc ',
          '#8abaf8',
          '#67a5ff',
          '#11abf3',
          '#fbe44d',
          '#55d9ff',
          '#74ccb1',
          '#ee8131'
        ],
        series: []
      }
      let chartData = this.data['web_main_platform'][index].trend
      option.xAxis.data = chartData.keys
      option.series = [
        {
          name: '走势简图',
          type: 'line',
          color: '#0099f0',
          smooth: true,
          symbolSize: 0,
          areaStyle: { normal: {} },
          data: chartData.data
        }
      ]
      let chart = globalCreateChart(element, option)
    },
    trendChart(id) {
      let chartData = this.data[id]
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          top: '5px',
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '40px',
          bottom: '20px',
          width: '90%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: { type: 'value' },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          }
        ],
        color: [
          '#83d944',
          '#f0a142 ',
          '#3178d2 ',
          '#568efc ',
          '#8abaf8',
          '#67a5ff',
          '#11abf3',
          '#fbe44d',
          '#55d9ff',
          '#74ccb1',
          '#ee8131'
        ],
        series: []
      }
      let seriesData = []
      _(chartData.data).forEach((item, index) => {
        seriesData.push({
          name: chartData.type[index],
          type: 'line',
          smooth: true,
          areaStyle: { normal: {} },
          data: item
        })
      })
      option.xAxis.data = chartData.keys
      option.series = seriesData
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
    },
    loopChart(id) {
      let chartData = this.data[id]
      let total = parseInt(chartData.gov) + parseInt(chartData.general)
      let rateMap = [
        ((chartData.gov / total) * 100).toFixed(2),
        ((chartData.general / total) * 100).toFixed(2)
      ]
      let legend = ['政府网站', '一般媒体']
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c}({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: '10px',
          bottom: '20px',
          data: legend,
          icon: 'circle',
          itemGap: 5,
          itemWidth: 10,
          itemHeight: 10,
          formatter: function(name) {
            let index = legend.indexOf(name)
            return name + ' ' + rateMap[index] + '%'
          }
        },
        color: ['#fd5066', '#009df6'],
        series: [
          {
            name: '媒体信息来源类型分布',
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { name: '政府网站', value: chartData.gov },
              { name: '一般媒体', value: chartData.general }
            ]
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
    },
    pointChart(id) {
      let chartData = this.data[id]
      let seriesData = []
      let xAxis = []
      var maxTotal = 0
      chartData.data.map((pdata, index) => {
        pdata.map((sdata, sindex) => {
          if (sdata.count > maxTotal) maxTotal = sdata.count
        })
      })
      chartData.keys = chartData.keys.map((item, index) => {
        return { value: [item, chartData.desc[index] ? chartData.desc[index] : ''] }
      })
      switch (id) {
        case 'web_key_media_trend':
          _(chartData.data).forEach((item, index) => {
            _(item).forEach((row, sub_index) => {
              let key = row.hasOwnProperty('keyword') ? 'keyword' : 'media'
              if (sub_index <= 9) {
                seriesData.push({
                  name: '发布数量',
                  type: 'heatmap',
                  data: [[sub_index, index, row.count]],
                  label: {
                    normal: {
                      show: true,
                      formatter: row[key].substr(0, 6)
                    }
                  }
                })
              }
            })
          })
          break
        case 'web_spread_content_trend':
          _(chartData.data).forEach((item, index) => {
            _(item).forEach((row, sub_index) => {
              seriesData.push({
                name: row.keyword,
                type: 'heatmap',
                data: [[sub_index, index, row.count]],
                label: {
                  normal: {
                    show: true,
                    formatter: row.media
                  }
                }
              })
            })
          })
          break
      }
      let option = {
        tooltip: {
          position: 'top',
          formatter: function(params) {
            if (id == 'web_key_media_trend') {
              return (
                params.seriesName +
                '<br>' +
                chartData.data[params.data[1]][params.data[0]].media +
                '：' +
                params.data[2]
              )
            } else {
              return params.seriesName + '：' + params.data[2]
            }
          }
        },
        animation: false,
        grid: {
          bottom: 30,
          left: 130
        },
        xAxis: {
          type: 'category',
          data: xAxis,
          splitArea: {
            show: true
          },
          show: true
        },
        yAxis: {
          type: 'category',
          data: chartData.keys,
          splitArea: {
            show: true
          },
          axisLabel: {
            formatter: function(arg) {
              return '{a|' + arg[0] + '} {b|' + arg[1] + '}'
            },
            rich: {
              b: {
                backgroundColor: '#e3eefd',
                padding: 3
              }
            }
          }
        },
        visualMap: {
          min: 0,
          max: maxTotal,
          calculable: true,
          orient: 'horizontal',
          show: false,
          inRange: {
            color: ['#ffbeb2', '#EE000F']
          }
        },
        series: seriesData
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
    },
    treeChart(id) {
      let chartData = this.data[id]
      if (JSON.stringify(chartData) === '{}') return false
      //        chartData.children = chartData.children.map((children, index) => {
      //          children.collapsed = true
      //          return children
      //        })
      let option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'tree',
            orient: 'horizontal', // vertical horizontal
            rootLocation: { x: 100, y: 230 },
            roam: true,
            data: [chartData],
            top: '1%',
            left: '7%',
            bottom: '1%',
            right: '20%',
            symbolSize: 7,
            label: {
              normal: {
                position: 'left',
                verticalAlign: 'middle',
                align: 'right',
                fontSize: 9
              }
            },
            leaves: {
              label: {
                normal: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left'
                }
              }
            }
          }
        ]
      }
      const echarts = globalGetEcharts()
      let element = window.document.getElementById(id)
      let chart = echarts.init(element)
      chart.setOption(option)
    },
    wordCloudChart(id) {
      let chartData = this.data[id]
      let option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '信息数',
            type: 'wordCloud',
            shape: 'circle',
            top: '10%',
            width: '100%',
            height: '85%',
            right: null,
            bottom: null,
            sizeRange: [12, 30],
            rotationRange: [-90, 90],
            rotationStep: 45,
            gridSize: 8,
            drawOutOfBound: false,
            textStyle: {
              normal: {
                fontFamily: 'sans-serif',
                //fontWeight: 'bold',
                color: function(params) {
                  // Random color
                  let color = ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e']
                  let index = Math.floor(Math.random() * color.length)
                  return color[index]
                }
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            data: []
          }
        ]
      }
      let seriesData = []
      _(chartData).forEach((item, index) => {
        seriesData.push({ name: item.keyword, value: item.total })
      })
      option.series[0].data = seriesData
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
    },
    wordPopChart(id) {
      let chartData = this.data[id]
      let maxr =
        chartData.length >= 1
          ? chartData.sort((a, b) => {
              return b.total - a.total
            })[0].total
          : 0
      function Bubble(option) {
        var _defaultOption = {
          width: 300,
          height: 300,
          padding: 1.5,
          data: '',
          conEle: ''
        }
        option = $.extend(true, _defaultOption, option)
        this.width = option.width
        this.height = option.height
        this.padding = option.padding
        this.data = option.data //数据url或对象,必填
        this.conEle = option.conEle //svg容器(node或者选择器)，必填
        this.mouseenter = function(d, node) {}
        this.mouseleave = function(d, node) {}
        this.click = function(d) {}
      }
      Bubble.prototype.init = function() {
        var that = this,
          //1.设置颜色
          color = d3.scale.category20c(),
          //2.布局
          bubble = d3.layout
            .pack()
            .sort(null)
            .size([that.width, that.height])
            .radius(d => {
              let val = (d / maxr) * 50
              return val < 20 ? 20 : val
            })
            .padding(that.padding),
          //3.添加svg元素
          svg = d3
            .select(that.conEle)
            .append('svg')
            .attr('width', that.width)
            .attr('font-size', '12')
            .attr('height', that.height)
        //4.数据请求及图形绘制
        if (typeStr(that.data) == '[object string]') {
          d3.json(that.data, function(error, data) {
            if (error) throw error
            //1.对数据进行处理
            data = dataHandle(data)
            render(svg, bubble, that, data)
          })
        } else {
          render(svg, bubble, that, dataHandle(that.data))
        }
      }
      function typeStr(obj) {
        return Object.prototype.toString.call(obj).toLowerCase()
      }
      //Returns a flattened hierarchy containing all leaf nodes under the root.
      function classes(root) {
        var classes = [] //存储结果的数组
        /*
         * 自定义递归函数
         * 第二个参数指传入的json对象
         */
        function recurse(name, node) {
          if (node.children) {
            //如果有孩子结点 （这里的children不是自带的，是json里面有的）
            node.children.forEach(function(child) {
              //将孩子结点中的每条数据
              recurse(node.name, child)
            })
          } else {
            //如果自身是孩子结点的，将内容压入数组
            classes.push({ name: node.name, value: node.size, props: node.props })
          }
        }
        recurse(null, root)
        return { children: classes }
      }
      function render(view, layout, context, data, cb) {
        var node = view
            .selectAll('.node')
            //绑定数据（配置结点）
            .data(
              layout.nodes(classes(data)).filter(function(d) {
                //数据过滤，满足条件返回自身（没孩子返回自身，有孩子不返回，这里目的是去除父节点）
                return !d.children
              })
            )
            .enter()
            .append('g')
            .attr('class', 'node')
            .attr('transform', function(d) {
              //设定g移动
              return 'translate(' + d.x + ',' + d.y + ')'
            }),
          usingNodes = node.filter(function(d) {
            return d.props.using
          }),
          time = +new Date(),
          duration = 1000,
          strokeWidth = 0
        node
          .append('circle')
          .attr('r', function(d) {
            //设置圆的半径
            return d.r
          })
          .style('fill', function(d) {
            //气泡颜色
            return d.props.color
          })
          .style('fill-opacity', 1)
        node
          .append('text')
          .attr('dy', '.3em')
          //设置文本对齐
          .style('text-anchor', 'middle')
          .style('font-size', '12px')
          .style('fill', function(d) {
            //字体颜色
            return '#fff'
          })
          //根据半径的大小来截取对应长度字符串(很重要)
          .text(function(d) {
            return d.name.substring(0, d.r / 8)
          })
        function animate() {
          var nowTime = +new Date()
          if (nowTime - duration > time) {
            time = nowTime
            strokeWidth = 0
          }
          strokeWidth += 0.6
          //strokeWidth >10?strokeWidth=10:strokeWidth += 1;
          usingNodes
            .select('circle')
            .style('stroke-width', strokeWidth + 'px')
            .style('stroke-opacity', '0.3')
            .style('stroke', function(d) {
              return d.props.color
            })
          requestAnimationFrame(animate)
        }
        animate()
        node.on('mouseenter', function(d) {
          var node = this
          context.mouseenter(d, node)
        })
        node.on('mouseleave', function(d) {
          var node = this
          context.mouseleave(d, node)
        })
        node.on('click', function(d) {
          var node = this
          context.click(d)
        })
      }
      //定义数据处理方法
      function dataHandle(data) {
        var result = {
          name: 'flare',
          children: []
        }
        data.forEach(function(ele) {
          result.children.push({
            name: ele.name,
            size: ele.value,
            props: ele.props
          })
        })
        return result
      }
      function createInfoTip(d) {
        var html = '<div class="node-info fz-12"><ul>'
        html += '<li class="info-title"><span>' + d.name + '</span></li>'
        if (d.props.color == '#66a5ff') {
          html +=
            '<li class="info-content"><i class="bg-normal"></i><span class="info-content-label">信息数' +
            '</span><span class="info-content-text">' +
            d.value +
            '</span></li>'
        } else {
          html +=
            '<li class="info-content"><i class="bg-abnormal"></i><span class="info-content-label">信息数' +
            '</span><span class="info-content-text">' +
            d.value +
            '</span></li>'
        }
        html += '</ul></div>'
        return html
      }
      // 清除旧数据
      window
        .jQuery('#' + id)
        .children()
        .remove()
      d3.select('#' + id)
        .selectAll('*')
        .remove()
      var data = []
      _(chartData).forEach((item, i) => {
        data[data.length] = {
          name: item.keyword,
          value: item.total,
          props: {
            abnormal: false,
            color: item.emotion == 'positive' ? '#66a5ff' : '#fa3851',
            using: false
          }
        }
      })
      let element = window.document.getElementById(id)
      if (!element) return false // 切换时直接break
      var option = {
        data: data,
        conEle: '#' + id,
        width: 530,
        height: 290,
        padding: 2
      }
      var bubble = new Bubble(option)
      bubble.click = function(d) {
        // TODO:: 列表点击事件
      }
      bubble.mouseenter = function(d, node) {
        var $con = $('#' + id)
        var rectBox = $con[0].getBoundingClientRect()
        d3.select(node).style('cursor', 'pointer')
        $con.append(createInfoTip(d))
        $('.node-info')
          .css({
            left: d3.event.x + 20 - rectBox.left,
            top: d3.event.y + 20 - rectBox.top
          })
          .show()
      }
      bubble.mouseleave = function(d) {
        $('.node-info').remove()
      }
      bubble.init()
    },
    eventTrend(id) {
      let data = this.data['web_event_trend'].data.trend
      let keys = []
      let seriesData = []
      data.map(item => {
        keys.push(item.date)
        if (item.points) {
          seriesData.push({
            value: item.count,
            symbol: 'image://' + findPointImg(item.points[0]),
            name: item.points.join(',')
          })
        } else {
          seriesData.push({
            value: item.count,
            name: ''
          })
        }
      })
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: keys
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'line',
            stack: '总量',
            symbol: 'none',
            symbolSize: 20,
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: function(params) {
                  return params.data.name
                }
              }
            },
            data: seriesData
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    findPointImg(name) {
      return findPointImg(name)
    }
  }
}
</script>
<style scoped>
.h-290 {
  height: 290px;
}
.scroll-tab {
  position: fixed;
  z-index: 3000;
  left: auto;
  right: auto;
  top: 200px;
  margin: auto;
}
.scroll {
  top: 0px;
}
.scroll .fxbg {
  box-shadow: 0px 3px 13px 0px rgba(194, 194, 194, 0.2);
  padding: 10px 0;
}
.flex-container {
  justify-content: space-between;
  align-content: space-between;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 1160px;
  float: none;
}
.btn_tab a {
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: rgba(237, 237, 237, 0.6);
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: block;
  padding: 0 50px;
  border: none;
  font-size: 14px;
}
.btn_tab a.selected {
  color: #fff;
  background: rgba(42, 103, 179, 1);
}
.echarts_empty {
  height: 280px;
}
/*时间轴*/
.demo {
  width: 1080px;
  margin: 20px auto;
  padding: 45px 0 0;
}
.main-timeline {
  position: relative;
}
.main-timeline:before,
.main-timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline:before {
  content: '';
  width: 3px;
  height: calc(100% - 50px);
  background: #bebebe;
  position: absolute;
  top: 0;
  left: 50%;
}
.main-timeline .timeline {
  width: 50%;
  float: left;
  position: relative;
  z-index: 1;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 7px;
}
.main-timeline .circle {
  width: 447px;
  height: 70px;
  background: rgb(239, 244, 254);
  border-radius: 8px;
  float: left;
  position: relative;
  color: #000;
  margin-top: -35px;
}
.main-timeline .circle .title,
.main-timeline .circle .name {
  height: 25px;
  line-height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  width: 100%;
  text-align: left;
  font-size: 12px;
}
.main-timeline .cont {
  display: table;
  padding: 0 5px;
  position: relative;
}
.main-timeline .year {
  display: block;
  margin: 0 0 50px 0;
  border-radius: 7px;
  font-size: 12px;
  color: #fff;
  width: 77px;
  height: 20px;
  background-color: #2a67b3;
  border-radius: 5px;
  margin-top: -28px;
  line-height: 20px;
  padding: 5px 3px;
  text-align: left;
  text-align: center;
}
.main-timeline .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #2a67b3;
  position: absolute;
  top: 0;
  right: -12px;
  display: inline-block;
}
.main-timeline .timeline:first-child .timeline-content:before {
  top: 7px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .circle {
  float: right;
}
.main-timeline .timeline:nth-child(even) {
  margin-top: 60px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:before {
  top: 3px;
}
.main-timeline .timeline:nth-child(even) .year {
  margin-top: -42px;
}
.main-timeline .timeline:nth-child(even) .icon {
  left: -10px;
  right: 0;
}
.main-timeline .mod-list {
  padding: 8px 10px;
}
/*主要发布渠道*/
.zyfbqd h3 {
  font-weight: 600;
}
.zyfbqd .mod-list-title2,
.zyfbqd .bt {
  padding-right: 10px;
  float: left;
}
.zyfbqd .mod-list-title2 {
  width: 140px;
}
.zyfbqd .bt {
  width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.zyfbqd .tag {
  display: inline-block;
  height: 36px;
}
.zyfbqd .xxzs {
  width: 56px;
  text-align: center;
}
/*事件传播内容*/
.sjcbnr .mod-list {
  margin-top: 10px;
  width: 130px;
}
.sjcbnr .mod-list li {
  height: 28px;
  line-height: 28px;
}
.sjcbnr .mod-list .num {
  margin-top: 6px;
}
.sjcbnr .mod-list a {
  width: 65px;
}
.h-355 {
  height: 355px;
}
/*事件关键时间点*/
.demo2 {
  width: 1120px;
  margin: 30px auto 0;
  padding: 0;
  position: relative;
}
.demo2:before {
  content: '';
  width: 3px;
  background: #bebebe;
  position: absolute;
  top: 40px;
  left: 10%;
  bottom: 40px;
}
.main-timeline2 {
  position: relative;
  padding-bottom: 10px;
}
.main-timeline2 .timeline {
  width: 90%;
  float: right;
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
}
.main-timeline2 .timeline:before,
.main-timeline2 .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-title {
  width: 10%;
  float: left;
  position: relative;
  z-index: 1;
  margin-top: 30px;
}
.main-title .point {
  /*height:70px;*/
  overflow: hidden;
}
.main-title p {
  line-height: 30px;
  overflow: hidden;
  font-weight: 600;
}
.main-title p span {
  font-size: 14px;
}
.main-title p .img {
  margin-right: 8px;
  vertical-align: middle;
}
.main-title h3 {
  width: 36px;
  height: 123px;
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(199, 199, 199, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 18px;
  font-size: 20px;
  color: rgba(0, 103, 177, 1);
  line-height: 30px;
  text-align: center;
  padding-top: 13px;
  margin-left: 25px;
}
.main-timeline2 .timeline:last-child,
.main-timeline2 .main-title:nth-last-child(2) {
  padding-bottom: 0;
}
.main-timeline2 .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline2 .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 45px;
}
.main-timeline2 .circle {
  width: 830px;
  float: right;
  position: relative;
  color: #000;
  text-align: left;
  overflow: hidden;
}
.main-timeline2 .circle_title,
.main-timeline2 .mod-list {
  background: rgb(239, 244, 254);
  border-radius: 8px;
  height: 60px;
  overflow: hidden;
}
.main-timeline2 .circle_title {
  line-height: 25px;
  padding: 8px 15px;
  margin-top: 15px;
}
.main-timeline2 .circle_title h4,
.main-timeline2 .circle_title p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 24px;
}
.main-timeline2 .circle_title p span {
  padding-right: 10px;
}
.main-timeline2 .circle_title p .bd {
  padding-left: 10px;
}
.main-timeline2 .circle_title dl {
  overflow: hidden;
  margin-bottom: 0;
}
.main-timeline2 .circle_title dd,
.main-timeline2 .circle_title a,
.main-timeline2 .circle_title .bd {
  float: left;
  font-size: 12px;
  color: #474747;
}
.main-timeline2 .circle_title .bd {
  padding: 0 15px;
}
.main-timeline2 .circle_title dd:last-child > .bd {
  display: none;
}
.main-timeline2 .circle .mod-list {
  padding: 10px 15px;
  height: auto;
}
.main-timeline2 .circle .mod-list li {
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  overflow: hidden;
}
.main-timeline2 .mod-list li a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
.main-timeline2 .mod-list li .mod-list-title2 {
  width: 680px;
  font-weight: 600;
}
.main-timeline2 .mod-list li .site {
  width: 80px;
  padding-left: 10px;
  color: #474747;
  float: left;
}
.main-timeline2 .site span {
  padding-right: 10px;
}
.main-timeline2 .cont {
  display: table;
  padding: 0 5px;
  position: relative;
  width: auto;
  margin: 0;
}
.main-timeline2 .year {
  display: block;
  margin: 0 0 0 27px;
  border-radius: 7px;
  font-size: 14px;
  color: #fff;
  width: 112px;
  height: 38px;
  background-color: #2a67b3;
  border-radius: 8px;
  margin-top: 2px;
  line-height: 38px;
}
.main-timeline2 .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #2a67b3;
  position: absolute;
  top: 38px;
  left: -7px;
  display: inline-block;
}
.main-timeline2 .item {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.demo2 .item:nth-child(2n) .timeline-content:before {
  display: none;
}
.demo2 .item:nth-child(2n) .main-title {
  margin-top: 10px;
}
.main-timeline2 .bd_n:before {
  display: none;
}
.item_title .main-title {
  margin-top: 0;
}
.main-timeline2 img {
  margin-right: 5px;
  float: left;
  margin-top: 7px;
}
.fxbg.pt_0 {
  padding-top: 0;
}
</style>